import b from 'b_';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';

import Button from 'spotii-ui/Button';
import Form, { FormRow } from 'spotii-ui/Form';
import FormError from 'spotii-ui/Form/FormError';
import Icon from 'spotii-ui/Icon';
import Input from 'spotii-ui/Input';
import message from 'spotii-ui/Message';

import { createMerchantEmployee, checkUniqueEmail } from 'api';
import { fetchRoles } from 'reducers/roles';
// import validate from './form/validation';
import formSerializer from './form/serializer';
import errorsSerializer from './form/errors';
import copyTextToClipboard from '../../utils/copyToClipboard';

import '../CheckoutList/CheckoutList.scss';
import './AddEmployee.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { adminRoles, itRoles } from '../EmployeeList/EmployeeList';

const cls = b.with('settings');

const CreateEmployeeForm = ({ appState, continueTo, onSuccess }) => {
  const { t } = useTranslation();
  const [selectedRole, setSelectedRole] = React.useState(null);
  const validate = (appState, values) => {
    const errors = {};
    const requiredFields = {
      first_name: t('firstNameRequired'),
      last_name: t('lastNameRequired'),
      email: t('emailIsRequired'),
      role_id: t('roleIsRequired'),
      address: t('addressIsRequired'),
      password: t('passwordIsRequired'),
    };

    Object.keys(requiredFields).forEach(key => {
      if ((!values[key] && key !== 'role_id') || (key === 'role_id' && !selectedRole)) {
        errors[key] = requiredFields[key];
      }
    });
    // Add password length validation rule
    if (values.password && values.password.length < 8) {
      errors.password = t('passwordMinEight');
    }

    // Add rule: password should not contain only numbers
    if (values.password && /^\d+$/.test(values.password)) {
      errors.password = t('passwordCannotBeOnlyNumbers');
    }
    return errors;
  };

  const dispatch = useDispatch();
  let roleList = useSelector(state => state.roles).list;
  const { permissions: userPermissions = [], display_name } = appState.loggedEmployee.role || {};
  const isCurrentUserItRole = itRoles.includes(display_name);
  if (isCurrentUserItRole || (userPermissions.length && !userPermissions.includes('admin_read'))) {
    roleList = roleList.filter(({ displayName }) => !adminRoles.includes(displayName));
  }
  const fetchParamsRoles = {
    merchantId: appState.currentUser.merchant.merchantId,
  };
  React.useEffect(() => {
    dispatch(fetchRoles(fetchParamsRoles));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roleSelectOptions = roleList.map(role => {
    return { value: role.roleId, label: role.displayName };
  });
  const onRoleSelectChange = e => {
    setSelectedRole(e.value);
  };

  const [uniqueEmailFailed, setUniqueEmailFailed] = React.useState(false);
  const handleBlurEmail = e => {
    const employeeEmail = e.target.value;
    checkUniqueEmail(employeeEmail).then(res => {
      if (res.data.exists === 'true') {
        setUniqueEmailFailed(true);
      } else {
        setUniqueEmailFailed(false);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      first_name: '',
      role_id: '',
      last_name: '',
      address: '',
      password: '',
    },
    validate: validate.bind(this, appState),

    // validationSchema,
    onSubmit: async (values, actions) => {
      const data = formSerializer(values);
      const checkoutData = {
        user: {
          email: data.email.toString().toLowerCase(),
          password: data.password,
          first_name: data.first_name,
          last_name: data.last_name,
          role_id: selectedRole,
          address: data.address,
        },
        merchant_id: appState.currentUser.merchant.merchantId,
      };
      actions.setStatus();
      try {
        const res = await createMerchantEmployee(checkoutData);
        actions.setStatus('success');
        message.success(t('employeeSuccessfullyAdded'));
        if (onSuccess) {
          onSuccess(data);
        }
      } catch (e) {
        console.warn(e);
        const errors = errorsSerializer(e.errors);
        if (errors) {
          actions.setErrors(errors);
        } else {
          message.error(
            <span>
              {`${t('formErrorTryAgainContact')} `}
              <a href="mailto:">hello@spendwisor.app</a>
            </span>,
          );
          actions.setStatus('error');
        }
      }
    },
  });
  const { values, touched, errors, isSubmitting, handleChange, handleBlur } = formik;

  if (formik.status === 'success' && continueTo) {
    return <Redirect to={continueTo} push />;
  }

  if (formik.status === 'error') {
    // Show generic error message
  }
  const getError = key => touched[key] && errors[key];

  const generatePassword = () => {
    const generatedPassword = Math.random()
      .toString(36)
      .slice(-17)
      .substr(2, 17);
    formik.setFieldValue('password', generatedPassword);
    copyTextToClipboard('Password', generatedPassword);
  };

  return (
    <div className={b('address-form')}>
      <Form onSubmit={formik.handleSubmit} autocomplete="off">
        <FormRow>
          <h4>{` ${t('employeeDetails')} `}</h4>
        </FormRow>
        <Row className={cls('section')} style={{ marginTop: '2em' }}>
          <Col md={12} sm={12} xs={12}>
            <Input
              style={{
                border: uniqueEmailFailed ? '2px solid #FF4D4A' : '',
                boxSizing: uniqueEmailFailed ? 'border-box' : '',
              }}
              name="email"
              label={`${t('email')} *`}
              type="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlurEmail}
              error={getError('email')}
            />
            {uniqueEmailFailed && (
              <span style={{ position: 'absolute' }}>
                <FormError>{t('emailAlreadyExists')}</FormError>
              </span>
            )}
          </Col>
        </Row>
        <Row className={cls('section')}>
          <Col md={8}>
            <Input
              name="password"
              label={`${t('password')} *`}
              type="password"
              onChange={handleChange}
              // onChange={handleChangePassword}
              onBlur={handleBlur}
              error={getError('password')}
              value={values.password}
            />
            {errors.password && touched.password ? <FormError>{errors.password}</FormError> : null}
          </Col>
          <Col md={4} style={{ padding: '0px' }}>
            <button
              type="button"
              onClick={generatePassword}
              style={{ backgroundColor: '#ffffff', border: 'none', height: '100%', padding: '0px' }}
            >
              {t('generateACopy')} <Icon type="generate" />
            </button>
          </Col>
        </Row>

        <Row className={cls('section')}>
          <Col md={12} sm={12} xs={12}>
            <Input
              name="first_name"
              label={`${t('firstName')} *`}
              type="text"
              value={values.first_name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={getError('first_name')}
            />
            {errors.first_name && touched.first_name ? (
              <FormError>{errors.first_name}</FormError>
            ) : null}
          </Col>
        </Row>

        <Row className={cls('section')}>
          <Col md={12}>
            <Input
              name="last_name"
              label={`${t('lastName')} *`}
              type="text"
              value={values.last_name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={getError('last_name')}
            />
            {errors.last_name && touched.last_name ? (
              <FormError>{errors.last_name}</FormError>
            ) : null}
          </Col>
        </Row>

        <Row className={cls('section')}>
          <Col md={12} id="createEmployeeRoleSelect">
            <Select
              options={roleSelectOptions}
              placeholder={t('selectRole')}
              onChange={e => {
                errors.role_id = null;
                onRoleSelectChange(e);
              }}
              controlShouldRenderValue
            />
            {errors.role_id && touched.role_id ? <FormError>{errors.role_id}</FormError> : null}
          </Col>
        </Row>

        <Row className={cls('section')}>
          <Col md={12}>
            <Input
              name="address"
              label={`${t('address')} *`}
              type="text"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              error={getError('address')}
            />
            {errors.address && touched.address ? (
              <FormError>{errors.address}</FormError>
            ) : null}
          </Col>
        </Row>

        <FormRow type="submit">
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            block
            loading={isSubmitting}
            disabled={isSubmitting || uniqueEmailFailed}
          >
            Add employee
          </Button>
        </FormRow>
      </Form>
    </div>
  );
};

CreateEmployeeForm.propTypes = {
  continueTo: PropTypes.string,
  onSuccess: PropTypes.func,
  appState: PropTypes.objectOf(PropTypes.object).isRequired,
};

CreateEmployeeForm.defaultProps = {
  continueTo: null,
  onSuccess: null,
};

export default CreateEmployeeForm;
